import { ComponentPreviewCard } from "./ComponentPreviewCard";
import ComponentsSearchBar from "./ComponentsSearchBar";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import NewsletterRow from "../newsletter/NewsletterRow";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import PageTitle from "../core/PageTitle";
import { RouteComponentProps } from "@reach/router";
import React, { useState, useEffect, useMemo } from "react";
import Select from "@material-ui/core/Select";
import Layout from "../core/Layout";
import FeaturedJobsSection from "../jobs/FeaturedJobsSection";
import MakerOSSection from "../ads/MakerOSSection";
import ComponentPreviewCardV2 from "../../tailwindComponents/ComponentPreviewCardV2";

type ComponentType = {
  id: string;
  title: string;
  description: string;
  contributorName: string;
  url: string;
  imageUrl: string;
  video?: any;
  image?: any;
  categories: string[];
  addedDate: Date;
};

export interface ComponentPreviewCardsSectionProps extends RouteComponentProps {
  pageContext: {
    componentsArray: [ComponentType];
    prefilteredComponentsArray: [ComponentType];
    githubResult: any[];
    searchQuery: [string];
    featuredJobs: any[];
  };
}

export default function ComponentPreviewCardsSection(
  props: ComponentPreviewCardsSectionProps
) {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState<string[]>(
    props.pageContext.searchQuery || []
  );
  const [sortBy, setSortBy] = useState(1);
  const [componentsArray, setComponentsArray] = useState<ComponentType[]>(
    props.pageContext.prefilteredComponentsArray || []
  );
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const allComponentCategories = props.pageContext.componentsArray.flatMap(
    (element) => {
      return element.categories;
    }
  );
  const uniqueComponentCategories = Array.from(new Set(allComponentCategories));
  const githubMap = useMemo(() => new Map<string, any>(), []);
  props.pageContext.githubResult.forEach((element) => {
    githubMap.set(element.url, element);
  });

  useEffect(() => {
    setComponentsArray(props.pageContext.prefilteredComponentsArray || []);
    setIsInitialLoad(false);
  }, [props.pageContext.prefilteredComponentsArray]);

  useEffect(() => {
    if (!isInitialLoad) {
      const filteredArray = (props.pageContext.componentsArray || []).filter(
        (element) => meetsSearchCriteria(element, searchQuery)
      );
      setComponentsArray(filteredArray);
    }
  }, [searchQuery, props.pageContext.componentsArray, isInitialLoad]);

  const pageTitle =
    props.pageContext.searchQuery.length < 1
      ? "Compose Catalog: Find the most popular Jetpack Compose libraries, tools and examples"
      : `Popular ${props.pageContext.searchQuery} libraries, tools and examples for Jetpack Compose`;

  const pageDescription =
    props.pageContext.searchQuery.length < 1
      ? "Discover top Jetpack Compose UI components for Android app development. Get code snippets and examples to boost your projects. Visit now!"
      : `Discover top Jetpack Compose UI components for Android app development. Get ${props.pageContext.searchQuery} code snippets and examples to boost your projects. Visit now!`;

  const sortedComponentsArray = useMemo(() => {
    return [...componentsArray].sort(
      (first: ComponentType, second: ComponentType) => {
        if (sortBy === 1) {
          // Sort by date added
          return (
            new Date(second.addedDate).getTime() -
            new Date(first.addedDate).getTime()
          );
        } else {
          // Sort by GitHub stars
          const firstGithubStars =
            githubMap.get(first.url)?.stargazers.totalCount || 0;
          const secondGithubStars =
            githubMap.get(second.url)?.stargazers.totalCount || 0;
          return secondGithubStars - firstGithubStars;
        }
      }
    );
  }, [componentsArray, sortBy, githubMap]);

  const handleSortChange = (event: any) => {
    setSortBy(event.target.value);
  };

  const handleSearchQueryChange = (event: any) => {
    setSearchQuery(event);
  };

  const pageSlug =
    props.pageContext.searchQuery.length > 0
      ? `/${props.pageContext.searchQuery[0]
          .split(" ")
          .join("-")}-libraries-in-Jetpack-Compose`
      : "/compose-catalog";
  return (
    <>
      <Layout
        maxWidth="lg"
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageSlug={pageSlug}
        pageImageUrl="/compose_catalog.png"
        seoImageUrl="/compose_catalog.png"
      >
        <Grid container spacing={2} className={classes.componentPageContainer}>
          <Grid container justify="center">
            <Grid key={"pageTitle"} item xs={12} md={6}>
              <PageTitle
                header="Jetpack Compose Catalog"
                subheader="Discover the most popular Jetpack Compose libraries, tools and examples!"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.formSubmission}>
            Have a project you'd like to submit?{" "}
            <OutboundLink
              href="https://airtable.com/shrULULFjz809iF0A"
              target="_blank"
              className={classes.formSubmissionLink}
            >
              Fill this form
            </OutboundLink>
            , will ya!
          </Grid>
          <Grid item xs={12} md={10}>
            <ComponentsSearchBar
              onChangeHandler={handleSearchQueryChange}
              componentCategories={uniqueComponentCategories.sort()}
              searchQuery={searchQuery}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="sort-by">Sort By</InputLabel>
              <Select
                native
                value={sortBy}
                onChange={handleSortChange}
                label="Sort By"
                inputProps={{
                  name: "sort-by-name",
                  id: "sort-by",
                }}
              >
                <option value={1}>Date Added</option>
                <option value={2}>Github Stars</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={4}>
              {sortedComponentsArray.map((metadata) => (
                <Grid key={metadata.id} item xs={12} sm={6} md={3} lg={3}>
                  <ComponentPreviewCardV2
                    title={metadata.title}
                    description={metadata.description}
                    image={metadata.image}
                    video={metadata.video}
                    resourceLink={metadata.url}
                    categories={metadata.categories}
                    githubStars={
                      githubMap.get(metadata.url)
                        ? githubMap.get(metadata.url).stargazers.totalCount
                        : null
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.jobs}>
          <NewsletterRow />
        </div>
      </Layout>
    </>
  );
}

function meetsSearchCriteria(
  element: ComponentType,
  searchQuery: string[]
): boolean {
  return (
    searchQuery.length === 0 ||
    element.categories.some((value: string) => searchQuery.includes(value))
  );
}

const useStyles = makeStyles({
  componentPageContainer: {
    padding: 16,
  },
  title: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  formSubmission: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Nunito Sans",
    color: "#222222",
    marginBottom: 16,
  },
  formSubmissionLink: {
    color: "#4636f7",
  },
  newsletter: {
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    textAlign: "center",
    marginTop: 64,
  },
  jobs: {
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    marginTop: 64,
    padding: 16,
  },
  formControl: {
    width: "100%",
  },
});
